import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Layout from '../components/layout'
import PageTitle from '../components/page-title'
import Phone from '../images/phone.png'
import SEO from '../components/seo'
import { myContext } from '../../provider'

const Content = styled(Row)`
  margin: 3.125rem 0;
`

const ContactDiv = styled.div`
  background-color: #05a8a8;
  color: white;
  border-radius: 3px;
  padding: 1rem 2rem;
`
const Quote = ({ data }) => {
  return (
    <myContext.Consumer>
      {({ language }) => {
        const nodes = data.allContentfulQuotePage.nodes
        const quotePageText = nodes.find((node) => node.language === language)
        return (
          <Layout>
            <SEO title="Quote" />
            <PageTitle title={quotePageText.title} />
            <Container style={{ minHeight: '70vh' }}>
              <Row>
                <p>{quotePageText.description.description}</p>
              </Row>
              <Content>
                <Col xs={12} md={6}>
                  <Form action="https://formspree.io/f/myybpqlq" method="POST">
                    <Form.Group>
                      <Form.Label>{quotePageText.nameLabel}</Form.Label>
                      <Form.Control
                        type="input"
                        name="Name"
                        placeholder={quotePageText.namePlaceholder}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{quotePageText.phone}</Form.Label>
                      <Form.Control
                        type="input"
                        name="Phone"
                        placeholder={quotePageText.phonePlaceholder}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{quotePageText.emailLabel}</Form.Label>
                      <Form.Control
                        type="email"
                        name="Email"
                        placeholder={quotePageText.emailPlaceholder}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{quotePageText.methodLabel}</Form.Label>
                      <Form.Control
                        type="text"
                        name="ContactMethod"
                        placeholder={quotePageText.methodPlaceholder}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{quotePageText.timeLabel}</Form.Label>
                      <Form.Control name="Time" type="time" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{quotePageText.commentsLabel}</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="Comments"
                        placeholder={quotePageText.commentsPlaceholder}
                      />
                    </Form.Group>
                    <Button type="submit">{quotePageText.submitText}</Button>
                  </Form>
                </Col>
                <Col xs={12} md={6}>
                  <div>
                    <img src={Phone} />
                  </div>
                  <ContactDiv>
                    <div>Prefer to contact us by phone?</div>
                    <div>Call us at: 604-437-6669</div>
                    <div>Monday - Friday : 9AM - 7PM</div>
                    <div>Saturday : 10AM - 6PM</div>
                    <div>Sunday : 11AM - 5PM</div>
                    <div>Holidays : Closed</div>
                  </ContactDiv>
                </Col>
              </Content>
            </Container>
          </Layout>
        )
      }}
    </myContext.Consumer>
  )
}

export const pageQuery = graphql`
  query QuotePageQuery {
    allContentfulQuotePage {
      nodes {
        title
        phonePlaceholder
        phone
        namePlaceholder
        nameLabel
        methodPlaceholder
        methodLabel
        language
        emailPlaceholder
        emailLabel
        commentsPlaceholder
        commentsLabel
        description {
          description
        }
        submitText
        timeLabel
      }
    }
  }
`
export default Quote
